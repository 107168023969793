<template>
  <textarea ref="input" style="opacity: none; position: absolute; left: -9999px" :value="value"></textarea>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useI18n } from "vue-i18n"
const { t } = useI18n()

const { value } = defineProps<{
  value: string
}>()

const input = ref(null)
const execute = () => {
  input.value.select()
  document.execCommand("copy")
  App.flashy(t("clipboardCopy"))
}

defineExpose({
  execute,
})
</script>
